import { authUrlOptions, redirectUiOptions } from './auth.service';

export const EnvEnum = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const ApiUrls = {
  development: 'https://at-dev-360-at-app.azurewebsites.net',
  staging: 'https://at-stg-360-at-app.azurewebsites.net',
  production: 'https://01-241-01-101.airtrade.com',
};

Object.freeze(EnvEnum);

export const GetEnvironment = () => {
  return process.env.REACT_APP_ENV;
};

export const GetEnvironmentSettings = () => {
  switch (GetEnvironment()) {
    case EnvEnum.DEVELOPMENT:
      return configFactory(
        authUrlOptions.dev,
        authUrlOptions.devToken,
        redirectUiOptions.currentPath,
        ApiUrls.development
      );
    case EnvEnum.STAGING:
      return configFactory(
        authUrlOptions.staging,
        authUrlOptions.stagingToken,
        redirectUiOptions.currentPath,
        ApiUrls.staging
      );
    case EnvEnum.PRODUCTION:
      return configFactory(
        authUrlOptions.prod,
        authUrlOptions.prodToken,
        redirectUiOptions.currentPath,
        ApiUrls.production
      );
  }
};

export const configFactory = (authUrl, authToken, redirectUrl, apiUrl) => {
  return {
    authUrl: authUrl,
    authToken: authToken,
    redirectUrl: redirectUrl,
    apiUrl: apiUrl,
  };
};

export const isProduction = () => {
  return process.env.REACT_APP_ENV === EnvEnum.PRODUCTION;
};

export const isProductionOrStaging = () => {
  return (
    process.env.REACT_APP_ENV === EnvEnum.PRODUCTION ||
    process.env.REACT_APP_ENV === EnvEnum.STAGING
  );
};

export const isDevelopment = () => {
  return process.env.REACT_APP_ENV === EnvEnum.DEVELOPMENT;
};

export const environment = () => {
  return process.env.REACT_APP_ENV;
};
